import React from "react";
import styled from "styled-components";
import BackgroundImage from 'gatsby-background-image'

const ImageWithTextOver = ({image, image_text, title}) => {
  return (
    <Container fluid={image.fluid}>
      <Content>
      <Title dangerouslySetInnerHTML={{ __html: title.html}} />
      <Text dangerouslySetInnerHTML={{ __html: image_text.html}} />
      </Content>
    </Container>
  );
};

export default ImageWithTextOver;

const Container = styled(BackgroundImage)`
  max-width: 90%;
  padding: 8px;
  margin: 0 auto;
  min-height: 400px;
  display: grid;
  place-items: center;
`;
const Content = styled.div`
  max-width: 628px;
  line-height: 1.5;
  font-size: 44px;
  line-height: 50px;
`;

const Title = styled.div`
  text-align: center;
  font-size: 54px;
  * {
    line-height: 1.1;
    color: white;
  }
`

const Text = styled.div`
  color: white;
  text-align: center;
  font-size: 15px;
  line-height: 26px;
`
