import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const useOwnershipData = previewData => {
  const staticData = useStaticQuery(
    graphql`
        {
            prismicOwnershipPage {
                _previewable
                uid
                data {
                    body {
                        ... on PrismicOwnershipPageBodyHeader {
                            slice_type
                            primary {
                                title {
                                    html
                                }
                                text {
                                    text
                                }
                                tagline {
                                    text
                                }
                            }
                        }
                        ... on PrismicOwnershipPageBodyVideo {
                            id
                            primary {
                                video_url {
                                    embed_url
                                }
                            }
                            slice_type
                        }
                        ... on PrismicOwnershipPageBodyAvailableWeeks {
                            slice_type
                            primary {
                                title {
                                    html
                                }
                            }
                        }
                        ... on PrismicOwnershipPageBodyApplications {
                            slice_type
                            primary {
                                title {
                                    html
                                }
                                text_two {
                                    text
                                }
                                text_one {
                                    text
                                }
                                button_text
                                button_link
                                image {
                                    alt
                                    fluid {
                                        aspectRatio
                                        sizes
                                        src
                                        srcSet
                                    }
                                }
                            }
                        }
                        ... on PrismicOwnershipPageBodyBook {
                            slice_type
                            primary {
                                text {
                                    text
                                }
                                link {
                                    url
                                    link_type
                                }
                                button_text
                            }
                        }
                        ... on PrismicOwnershipPageBodyImageWithTextOver {
                            id
                            primary {
                                image {
                                    alt
                                    fluid {
                                        aspectRatio
                                        src
                                        srcSet
                                        base64
                                    }
                                    url
                                }
                                image_text {
                                    html
                                }
                                title {
                                    html
                                }
                            }
                            slice_type
                        }
                    }
                }
            }
        }

    `
  )
  const mergedData: any = mergePrismicPreviewData({ staticData, previewData })
  const { uid, data } = mergedData.prismicOwnershipPage
  const sections = data.body.filter(
    section =>
      section.slice_type !== "book" && section.slice_type !== "applications"
  )
  const bookData = data.body.find(section => section.slice_type === "book")

  const tellUsData = data.body.find(
    section => section.slice_type === "applications"
  ).primary

  return {
    uid,
    sections,
    bookData: bookData ? bookData.primary : undefined,
    tellUsData,
  }
}
